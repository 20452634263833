import { graphql, Link } from 'gatsby'
import React, { ReactElement } from 'react'
import styled from 'styled-components';
import Layout from '../components/Layout'

const SubCategoryListGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  	@media only screen and (min-width: 600px) {
		grid-template-columns: 1fr 4fr 1fr;
	}

  	@media only screen and (min-width: 768px) {
		grid-template-columns: 1fr 2fr 1fr;
	}
`;

const CategoryListContainer = styled.div`
  grid-column-start: 1;

  	@media only screen and (min-width: 600px) {
		grid-column-start: 2;
	}
`;

const ArticleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	
	@media only screen and (min-width: 992px) {
		flex-direction: column;
	}	
`;

const H2 = styled.h2`
  	color: #333;
	font-size: 120%;
	line-height: 1.2;
  	margin-bottom: 10px;
	//width: 60%;

	@media only screen and (min-width: 992px) {		
		margin-top: 0.5em;
	}
`;

const TextAndImageContainer = styled.div`
	display: flex;
`;

const ImageContainer = styled.div`
	width: 40%;
`;

const TextContainer = styled.div`
	display: none;

	@media only screen and (min-width: 992px) {		
		width: 60%;
		display: block;
	}	
`;

export default function SubCategoryArticlePage({ data }): ReactElement {
	return (
		<Layout>
			<SubCategoryListGridContainer>
				<CategoryListContainer>
					{
						data.allCosmicjsArticles.edges.map(e => {
							return <Link className={"article"} to={`/${e.node.metadata.sub_category}/${e.node.slug}`}>
								<ArticleContainer>
									<H2>{e.node.title}</H2>
									<TextAndImageContainer>
										<TextContainer>{e.node.metadata.short_version}</TextContainer>
										<ImageContainer>
											<img src={e.node.metadata.thumbnail.imgix_url + '?q=&h=150'} alt={e.node.metadata.thumbnailcaption} />
											<figcaption>{e.node.metadata.thumbnailcaption}</figcaption>
										</ImageContainer>
									</TextAndImageContainer>
								</ArticleContainer>
								<hr />
							</Link>;
						})
					}
				</CategoryListContainer>
			</SubCategoryListGridContainer>
		</Layout>
	)
}

export const pageQuery = graphql`
  query ArticlesBySubCategory($subcategory: String!) {
    allCosmicjsArticles(filter: {metadata: {sub_category: {eq: $subcategory}}}) {
		edges {
			node {
				metadata {
			  		is_production,
			  		thumbnail {
						imgix_url
			  		},
			  		short_version,
			  		sub_category
				}
				slug
				title				
				created(formatString: "DD MMMM, YYYY")
			}
		}
	  }
  }
`